import React from "react"

const PageContent = (props) => {
    var content = "";

    if (props.content) {
      content = props.content
      if(props.content.indexOf('portstest.azurewebsites.net') > -1 || props.content.indexOf('pojcontent.azurewebsites.net') > -1 || props.content.indexOf('pojcontentlinux.azurewebsites.net') > -1)
        content = props.content.replace("http://"+process.env.GATSBY_WORDPRESS_PREVIEW,"").replace("https://"+process.env.GATSBY_WORDPRESS_PREVIEW,"").replace("/ports","").replace("/jerseyairport","").replace("/default","");
    }
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
}
export default PageContent